import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { statusResponseSchema } from 'src/data-contract/schemas/statusResponseSchema';
import { ValidatingHttpClient } from 'src/app/core/services/validating-http-client';
import { ApiResult } from 'src/app/core/model/api-result';

export interface HealthCheckStatus {
  status: string;
}

@Injectable({
  providedIn: 'root',
})
export class HealthCheckApiService {
  private baseUrl = `${environment.apiUrl}/status`;

  constructor(private readonly validatingHttpClient: ValidatingHttpClient) {}

  getHealthCheckStatus(): Observable<ApiResult<HealthCheckStatus, Error>> {
    return this.validatingHttpClient
      .get(statusResponseSchema, `${this.baseUrl}`)
      .pipe(
        map((result) => {
          if (result.isError) {
            return ApiResult.ok({
              status: $localize`Unavailable (${result.error instanceof HttpErrorResponse ? result.error.statusText : 'Unknown error'})`,
            });
          }

          return result;
        })
      );
  }
}
