/// <reference types="@angular/localize" />

import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { ModuleRegistry } from 'ag-grid-community';
import { AllEnterpriseModule } from 'ag-grid-enterprise';
import { de } from 'date-fns/locale';
import { LOCALE_ID } from '@angular/core';
import { setDefaultOptions } from 'date-fns/setDefaultOptions';
import { DefaultOptions } from 'date-fns/_lib/defaultOptions';

// Register AG grid modules
ModuleRegistry.registerModules([AllEnterpriseModule]);

bootstrapApplication(AppComponent, appConfig)
  .then((app) => {
    const localeId = app.injector.get(LOCALE_ID);
    if (localeId.startsWith('de')) {
      // Cast needebecause of a typing issue with date-fns DefaultOptions
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      setDefaultOptions({ locale: de } as DefaultOptions);
    }
  })
  .catch((err) => console.error(err));
